import SearchBill from "../../../cpnTemplate/SearchBill/SearchBill";
import NavChildAdmin from "../../navAmin/NavChildAdmin/NavChildAdmin";

function SearchBillAdmin() {
  return (
    <>
      <NavChildAdmin />
      <SearchBill />
    </>
  );
}

export default SearchBillAdmin;
