import SearchBill from "../../../cpnTemplate/SearchBill/SearchBill";
import NavBar from "../../../components/NavBar/NavBar";

function FormSearchBill() {
  return (
    <>
      <NavBar />
      <SearchBill />
    </>
  );
}

export default FormSearchBill;
