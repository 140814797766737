import React, { useState, useEffect } from "react";
import "./InsertCode.scss";
// import InvoiceBill from "../Share/InvoiceBill/InvoiceBill";
import { message } from "antd";
import axios from "axios";
// import TableBill from "../../Admin/Dashboard/TableBill/TableBill";
import TableBillUser from "../TableBillUser/TableBillUser";

function InsertCode() {
  const [successMess, setSuccessMess] = message.useMessage();
  const [valueSearchCode, setValueSearchCode] = useState("");
  const [billData, setBillData] = useState([]);
  const handleSearchCode = async () => {
    if (valueSearchCode !== "") {
      // console.log(valueSearchCode);
      try {
        const response = await axios.get(
          `https://qrcodeweb-api.vercel.app/api/bills/${valueSearchCode}`
        );
        // console.log(response.data);
        if (response.data) {
          const isElementExists = billData?.find(
            (item) => item.billID === valueSearchCode
          );
          if (!isElementExists) {
            const updatedBillData = [...billData, response.data];
            setBillData(updatedBillData);
            localStorage.setItem('billIDs', JSON.stringify(updatedBillData.map(item => item.billID)));
            // message
            successMess.open({
              type: "success",
              content: "Tra cứu hóa đơn thành công",
            });
            setValueSearchCode("");
          } else {
            successMess.open({
              type: "error",
              content: "Mã tra cứu đã tồn tại",
            });
            setValueSearchCode("");
          }
        } else {
          successMess.open({
            type: "error",
            content: "Không có dữ liệu hóa đơn này",
          });
          setValueSearchCode("");
        }
      } catch (error) {
        console.error("Error fetching drinks:", error);
        successMess.open({
          type: "error",
          content: "Tra cứu hóa đơn thất bại, hãy xem lại mã đã nhập",
        });
      }
    } else {
      successMess.open({
        type: "error",
        content: "Nhập lại mã",
      });
    }
  };
  const handleClearData = () => {
    // xóa dữ liệu trên localStorage
    localStorage.removeItem('billIDs');
    // Xóa mảng trong state
    setBillData([]);

    // Hiển thị thông báo
    successMess.open({
      type: "success",
      content: "Xóa hóa đơn thành công",
    });
  };

  return (
    <>
      {setSuccessMess}
      <div id="InsertCode">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="form_container">
            <div className="title_container">
              <p className="title">Tra cứu</p>
              <span className="subtitle">Nhập mã voucher tại đây</span>
            </div>
            <div className="input_container">
              <label className="input_label" htmlFor="email_field">
                Code
              </label>
              <input
                placeholder="TXTXXXXXX"
                title="Email"
                name="input-name"
                type="text"
                className="input_field"
                id="email_field"
                value={valueSearchCode}
                onChange={(e) => setValueSearchCode(e.target.value)}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <button className="sign-in_btn" onClick={handleSearchCode}>
                <span>Tra cứu</span>
              </button>
              <button className="clear-in_btn" onClick={handleClearData}>
                <span>Xóa</span>
              </button>
            </div>
          </div>
        </div>
        <TableBillUser billToDate={billData} />
      </div>
    </>
  );
}

export default InsertCode;
