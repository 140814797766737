import FormBabyAdmin from "../../../cpnTemplate/formBbAdmin/FormBabyAdmin";
import NavChildAdmin from "../../navAmin/NavChildAdmin/NavChildAdmin";

function AddVoucher() {
  return (
    <>
      <NavChildAdmin />
      <FormBabyAdmin />
    </>
  );
}

export default AddVoucher;
