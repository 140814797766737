import NavBar from "../../../components/NavBar/NavBar";
import FormBabyAdmin from "../../../cpnTemplate/formBbAdmin/FormBabyAdmin";

function FormAddVoucher() {
  return (
    <>
      <NavBar />
      <FormBabyAdmin />
    </>
  );
}

export default FormAddVoucher;
